body {
  font-size:16px;
}

.App {
  text-align: left;
}

h3 {
  font-weight:normal !important;
}

.hidden-li {
  list-style: none;
  padding-left:0px;
}

.no-underline {
  text-decoration: none;
}

.app-theme-choices li { 
  padding:10px 0;
}

.narrow-screen {
  display:none;
}

.app-title {
  font-size:1em;
}

.app-title-tiny {
  margin:0px;
  padding: 6px 6px 0px 6px;
  font-size:0.8em;
  display:flex;
  justify-content:space-between;
}

.header-button {
  padding-left:15px;
}

.app-subtitle {
  font-size:.6em;
  line-height:.6em;
}

.page {
  padding:10px;
}

.header-clock-container {
  width:100%;
  display:flex;
  flex-flow:row;
  justify-content:center;
}

.header-clock-and-date {
  padding-bottom:10px;
}

.header-row-item a {
  text-decoration: none;
}

.browser-date {
  text-align:center;
}

.browser-time {
  text-align:center;
  font-size:3em;
}

.bg-evening button {
  color:white;
}

.bg-sunset button {
  color:black;
}

.bg-night {
  background-color:#282c34;
  color:white;
}

.bg-night button {
  color:white;
}

.navigation-links a {
  font-size:1em;
  color:black;
  text-decoration: none;
}

.navigation-links a:visited {
  color:black;
}

.navigation-links ul {
  list-style-type: none;
  display: flex;
  flex-direction:column;
  justify-content: right;
  margin-right:30px;
}

.navigation-links ul li {
  display: inline;
}

.clocks-quick-options {
  display:flex;
  flex-flow:row;
  justify-content:right;
  padding:10px;
  gap:20px;
  justify-content:space-between;
}

.clocks-row-container {
  display: flex;
  flex-flow:row wrap;
  flex-direction: row;
  justify-content: center;
  gap:12px;
  margin:0 0 20px 0;
}

.clock-container {
  height:300px;
  width:300px;
  justify-content:center;
  border-radius:50%;
  text-align:center;
  padding-top:50px;
  font-size:1.3em;
  flex-basis:none;
}

.clock-top-row-container {
  display:flex;
  flex-direction:row;
  justify-content:center;
}

.clock-display-date {
  font-size:1em;
  font-weight:normal;
}

.timezone-select-button {
  padding:0px !important;
  border:0px !important;
}

.time-col-container {
  flex-direction: column;
  padding:5px;
}

.clock-manage-button-container {
  display:flex;
  justify-content:center;
}

.modal-line {
  min-height:42px;
}

.modal-label {
  font-weight:bold;
}

.nickname-button {
  padding:0 !important;
  text-decoration:none !important;
}

.delete-clock-div {
  display:flex;
  justify-content:right;
}

.select-timezone {
  color:black;
}

.edit-clock-name-row {
  display:flex;
  justify-content:space-between;
}

.edit-clock-name-buttons {
  display:flex;
  gap:10px;
}

.time-item {
  flex-grow:1;
}

.timestamp {
  font-variant-numeric: tabular-nums lining-nums;
}

.time-stamp-display {
  font-size:4.4em;
}

.blue-border {
  border: 1px solid blue;
}

.name-clock-link {
  font-size:1.2em !important;
  text-align:center !important;
  border:none !important;
  width:230px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none !important;
}

.name-clock-link.btn.btn-sm {
  font-size: 1em;
}

.App-header {
  background-color: #282c34;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.footer {
  padding:10px;
  display:flex;
  justify-content:space-between;
}

.counter-container {
  display:flex;
  flex-direction:column;
}

.counter-item {
  flex:1;
}

.red-btn {
  color:red;
}

.calculator-container {
  display:flex;
  flex-direction:column;
  justify-content:center;
  margin: 10px;
}

.calculator-input {
  width:80% !important;
}

.calculator-buttons-container {
  display:flex;
  justify-content:center;
  align-items:center;
}

.calculator-result-item {
  flex: 0 0 120px;
  padding-left:6px;
  line-height:32px;
}

.settings-container {
  margin: 10px;
}

.previous-calculations-title {
  margin-top:20px;
}

.timezone-modal {
  height:456px;
}

.timezone-input::placeholder {
  color:#282c34 !important;
}

.timezone-picker-list {
  height:300px;
  position:relative;
  overflow-y:auto;
  padding-left:0px;
  border: 1px solid #e6ebec;
  border-radius: 0 0 3px 3px;
  background-color:white;
}

.timezone-picker-list-item {
  padding: 3px 12px;
  margin-left:0;
  background:white;
}

.timezone-picker-list-item-focused {
  background: #ececec;
}

.timezone-picker-list-item:hover {
  background: #ececec;
}

.timezone-picker-list li {
  list-style:none;
}

.thermometer {
  padding: 10px;
  width:100%;
}

.thermometer-container {
  margin:2px 0 2px 0;
  height:50px;
  display:flex;
  flex-direction: row;
}

.therm-unit {
  width:100%;
  display:flex;
  flex-direction: column-reverse;
  position: relative;
}

.therm-number {
  display: none;
  margin: 0 auto 0 auto;
  font-size:8px;
}

.therm-unit-c {
  justify-content: flex-end;
}

.therm-unit-f {
  justify-content: flex-start;
}

.letter {
  margin-left:6px;
}

.mercury {
  margin: 1px 0 1px 0;
  height:4px;
  background-color:red;
}

@media only screen and (min-width: 781px) {
  /* Hide all by default, unhide based on screen width */
  .therm-line {
    height: 50%;
    margin:0 auto 0 auto;
    display:block;
    border-right: 1px solid gray;
  }

  /****** Line and number treatment - both bars ******/

  /* So the first/last lines display */
  .thermometer-container .therm-unit:first-of-type .therm-line-c,
  .thermometer-container .therm-unit:first-of-type .therm-line-f,
  .thermometer-container .therm-unit:last-of-type .therm-line-c, 
  .thermometer-container .therm-unit:last-of-type .therm-line-f
   {
    display: block;
    border-right: 2px solid black;
    height:70%;
  }

  /* So the first/last number displays */
  .thermometer-container .therm-unit:first-of-type .therm-number-c,
  .thermometer-container .therm-unit:first-of-type .therm-number-f,
  .thermometer-container .therm-unit:last-of-type .therm-number-c, 
  .thermometer-container .therm-unit:last-of-type .therm-number-f {
    display: block;
  }

  /* Line and number treatment for Celcius */
  .thermometer-container .therm-unit:nth-of-type(5n) .therm-line-c {
    border-right: 2px solid black;
    height:70%;
  }

  .thermometer-container .therm-unit:nth-of-type(5n) .therm-number-c {
    display: block;
    position: absolute;
    bottom: 2px;
    text-align: center;
    transform: translateX(-10%);
    width: 12px;
  }

  /* Line and number treatment for Fahrenheit */
  .thermometer-container .therm-unit:nth-of-type(5n+1) .therm-line-f {
    border-right: 2px solid black;
    height:70%;
  }

  .thermometer-container .therm-unit:nth-of-type(5n+1) .therm-number-f {
    display: block;
    position: absolute;
    text-align: center;
    top: 2px;
    transform: translateX(-25%);
    width: 13px;
  }
}


/* Two columns of clocks */
@media only screen and (max-width: 780px) {

  .page {
    padding:0;
  }

  .clocks-row-container {
    justify-content: left;
    gap:0px;
  }

  .clock-top-row-container {
    display:flex;
    flex-direction:row;
    justify-content:space-between;
  }

  .clock-container {
    width:100%;
    height:120px;
    border-radius:0%;
    text-align:left;
    padding-top:10px;
    padding-left:10px;
    font-size:0.8em;
    flex-basis:50%;
  }

  .clock-container:hover {
    filter: brightness(115%);
  }

  .name-clock-link {
    text-align:left !important;
    width:180px;
    padding:0px !important;
  }

  .clock-manage-button-container {
    justify-content:left;
  }

  .time-col-container {
    padding-top:0px;
  }

  .time-stamp-display {
    font-size:2.2em;
  }

  .navigation-links ul {
    margin-right:2px;
  }

  .browser-time {
    text-align:center;
    font-size:2em;
  }

  /* Hide all by default, unhide based on screen width */
  .therm-line {
    height:50%;
    margin:0 auto 0 auto;
    display:none;
    border-right: 1px solid gray;
  }

  /****** Line and number treatment - both bars ******/

  /* So the first/last lines display */
  .thermometer-container .therm-unit:first-of-type .therm-line-c,
  .thermometer-container .therm-unit:first-of-type .therm-line-f,
  .thermometer-container .therm-unit:last-of-type .therm-line-c, 
  .thermometer-container .therm-unit:last-of-type .therm-line-f
   {
    display: block;
    border-right: 2px solid black;
    height:70%;
  }

  /* So the first/last number displays */
  .thermometer-container .therm-unit:first-of-type .therm-number-c,
  .thermometer-container .therm-unit:first-of-type .therm-number-f,
  .thermometer-container .therm-unit:last-of-type .therm-number-c, 
  .thermometer-container .therm-unit:last-of-type .therm-number-f {
    display: block;
  }

  /****** Line and number treatment for Celcius ******/

  /* So we count by 2's on a narrow screen */
  .thermometer-container .therm-unit:nth-of-type(2n) .therm-line-c {
    display: block;
  }

  /* So every 10th line is bolded and has a number under it */
  .thermometer-container .therm-unit:nth-of-type(10n) .therm-line-c {
    display: block;
    border-right: 2px solid black;
    height:70%;
  }

  .thermometer-container .therm-unit .therm-number-c {
    display: none;
    position: absolute;
    bottom: 2px;
    text-align: center;
    transform: translateX(-10%);
    width: 12px;
  }

  /* So every 10th Celcius value displays */
  .thermometer-container .therm-unit:nth-of-type(10n) .therm-number-c {
    display: block;
    position: absolute;
    bottom: 2px;
    text-align: center;
    transform: translateX(-10%);
    width: 12px;
  }

  /* Line and number treatment for Fahrenheit */
  .therm-line-f {
    height: 50%;
    display: none;
  }

  .thermometer-container .therm-unit:nth-of-type(2n+1) .therm-line-f {
    display: block;
  }

  .thermometer-container .therm-unit:nth-of-type(10n+1) .therm-line-f {
    display:block;
    border-right: 2px solid black;
    height:70%;
  }

  .thermometer-container .therm-unit:nth-of-type(10n+1) .therm-number-f {
    display: block;
    position: absolute;
    text-align: center;
    top: 2px;
    transform: translateX(-25%);
    width: 13px;
  }
}

.clock-display-preview-time {
  font-size:.8em;
}

/* Two columns of clocks */
@media only screen and (max-width:450px) {

  .name-clock-link {
    width:160px;
  }
}

/* Narrowest screen - clocks are 100% of screen width */
@media only screen and (max-width: 360px) {

  .clock-container {
    flex-basis:100%;
  }

  .name-clock-link {
    text-align:left;
    width:240px;
  }

  .clock-top-row-container {
    justify-content:left;
  }

}